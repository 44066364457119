import ChannelsSvg from './sideNavIcons/channels.svg';
import BuilderSvg from './sideNavIcons/builder.svg';
import ConnectSvg from './sideNavIcons/connect.svg';
import DashboardSvg from './sideNavIcons/dashboard.svg';
import AdminsSvg from './sideNavIcons/admins.svg';
import SettingsSvg from './sideNavIcons/settings.svg';
import UsersSvg from './sideNavIcons/users.svg';
import EmergencySvg from './sideNavIcons/Emergency Tecnical Support.svg';

import ChannelsGradientSvg from './sideNavIcons/channels_gradient.svg';
import BuilderGradientSvg from './sideNavIcons/builder_gradient.svg';
import ConnectGradientSvg from './sideNavIcons/connect_gradient.svg';
import DashboardGradientSvg from './sideNavIcons/dashboard_gradient.svg';
import AdminsGradientSvg from './sideNavIcons/admins_gradient.svg';
import SettingsGradientSvg from './sideNavIcons/settings_gradient.svg';
import UsersGradientSvg from './sideNavIcons/users_gradient.svg';
import EmergencyGradientSvg from './sideNavIcons/Emergency Tecnical Support_gradient.svg';

export const menuOptions = [
  // {
  //   icon: ChannelsSvg,
  //   key: 'channels',
  //   path: '/app/:id/groups',
  //   tooltipContent: 'Channels',
  // },
  {
    icon: BuilderSvg,
    selectedIcon: BuilderGradientSvg,
    key: 'builder',
    path: '/app/:id/groups/:groupId/channels',
    isDefault: true,
    tooltipContent: 'App Builder',
    title: 'Builder'
  },
  {
    icon: UsersSvg,
    selectedIcon: UsersGradientSvg,
    key: 'users',
    path: '/app/:id/basic',
    tooltipContent: 'Users',
    title: 'Users'
  },
  {
    icon: ConnectSvg,
    selectedIcon: ConnectGradientSvg,
    key: 'connect',
    path: '/app/:id/connect',
    tooltipContent: 'Connect',
    title: 'Connect'
  },
  {
    icon: DashboardSvg,
    selectedIcon: DashboardGradientSvg,
    key: 'dashboard',
    path: '/app/:id/dashboard',
    tooltipContent: 'Analytics',
    title: 'Analytics'
  },
  {
    icon: SettingsSvg,
    selectedIcon: SettingsGradientSvg,
    key: 'settings',
    path: '/app/:id/settings',
    tooltipContent: 'Settings',
    title: 'Settings'
  },
  // {
  //   icon: AdminsSvg,
  //   selectedIcon: AdminsGradientSvg,
  //   key: 'admins',
  //   path: '/app/:id/admins',
  //   tooltipContent: 'Admin',
  //   title: 'Admins',
  // },
  {
    icon: EmergencySvg,
    selectedIcon: EmergencyGradientSvg,
    key: 'emergency',
    tooltipContent: 'Support',
    title: 'Support',
    onClick : () => {
      window.open(
        `https://cardware.zendesk.com/hc/en-us`,
        '_blank'
      );
    }
  },
  {
    icon: ChannelsSvg,
    selectedIcon: ChannelsGradientSvg,
    path: '/app/:id/library',
    key: 'library',
    tooltipContent: 'Library',
    title: 'Library',
  },
  {
    icon: ChannelsSvg,
    selectedIcon: ChannelsGradientSvg,
    path: '/app/:id/reward/Requested',
    key: 'rewards',
    tooltipContent: 'Rewards',
    title: 'Rewards',
  },
];
