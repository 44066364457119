module.exports = {
  author: 'Satya',
  authorUrl: '',
  title: 'Admin | 2.1',
  description: 'Description will go here!',
  url: 'https://www.catalystxl.com',
  logo: 'static/images/channle.png',
  legalName: 'Satya',
  socialLinks: {
    instagram: '',
    twitter: '',
  },
  social: {
    instagramId: '',
    twitter: '',
  },
  googleAnalyticsID: 'UA-XXXXXXXX-X',
  themeColor: '#000',
  backgroundColor: '#fff',
  address: {
    city: 'Panama City',
    country: 'Panama',
  },
  contact: {
    email: 'satya@catalystxl.com',
  },
  foundingDate: '2020',
  zendeskKey: "1057344b-592e-4a3c-a04b-811f15195bee"
};
